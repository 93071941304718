import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./Slider.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import logo from "../assest/pp.JPG";
import slide1 from "../assest/pn.JPG";
import slide2 from "../assest/ps.JPG";
import slide from "../assest/shah2.jpg";
function Slider() {
  const { t } = useTranslation();
  const [sliderData, setSliderData] = useState([]);

  useEffect(() => {
    const fetchSliderData = async () => {
      try {
        const response = await fetch("http://localhost:3001/api/v1/slider");
        if (!response.ok) {
          throw new Error("Failed to fetch slider data");
        }
        const data = await response.json();
        setSliderData(data.data); // Assuming your API response contains an array of slider data
      } catch (error) {
        console.error("Error fetching slider data:", error);
      }
    };

    fetchSliderData();
  }, []);

  return (
    <div className="slider">
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        loop={true}
        speed={500}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        className="mySwiper"
      >
        <SwiperSlide>
          <img src={slide} alt="nasralaah" />{" "}
         
                
        </SwiperSlide>

        <SwiperSlide>
          <img src={logo} alt="" />{" "}
          <div className="slider-text">
            {" "}
            <h1> {t("slider.title")} </h1> <h2> ( {t("slider.sub")} )</h2>{" "}
            <p> </p>{" "}
            <div className="my-btn">
              <Link to={"/gallery"} className="btn">
                {" "}
                {t("slider.gallery")}{" "}
              </Link>{" "}
              <Link to={"/about"} className="btn">
                {" "}
                {t("slider.more")}{" "}
              </Link>{" "}
            </div>{" "}
          </div>{" "}
        </SwiperSlide>

        <SwiperSlide>
          <img src={slide1} alt="" />{" "}
          <div className="slider-text2">
            <h1> {t("development_focus")} </h1>
            {/* <p> </p> <div className='my-btn'>
                <Link to={'/gallery'} className='btn'> {t('slider.gallery')} </Link> 
                <Link to={'/about'} className='btn' > {t('slider.more')} </Link> </div> */}
          </div>{" "}
        </SwiperSlide>

        {/* <SwiperSlide>
          <img src={slide2} alt="" />{" "}
          <div className="slider-text2">
            <h1> {t("company_slogan")} </h1>
           
          </div>{" "}
        </SwiperSlide> */}

        {sliderData.map((slide, index) => (
          <SwiperSlide key={index}>
            <img src={`http://localhost:3001${slide.image}`} alt="" />
            <div className="slider-text">
              <h1>{slide.title}</h1>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default Slider;
